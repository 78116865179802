.homeContainer {
	width: 100%;
	max-width: 2500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: relative;
	height: 100%;
	gap: 2.8rem;
	margin-top: 1.5rem;
	background: #FFF;

	@media screen and (min-width: 730px) {
		gap: 5.5rem;
		padding: 0 2.33rem;
		margin-bottom: 2.66rem;
		margin-top: 3.31rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 7rem;
		padding: 0 2.87rem;
		margin-bottom: 4.01rem;
		margin-top: 3.81rem;
	}
	@media screen and (min-width: 1200px) {
		padding: 0 5.3rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 6rem;
		padding: 0 8.25rem;
		margin-bottom: 6.04rem;
		margin-top: 4.75rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 7.75rem;
		padding: 0 16.25rem;
		margin-bottom: 6.3rem;
		margin-top: 4rem;
	}
}

.headerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	gap: 5.5rem;
	padding: 2.63rem 1.21875rem 2.62rem 1.28125rem;

	@media screen and (min-width: 730px) {
		gap: 1.42rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.75rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6rem;
	}
}
.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.25rem;

	@media screen and (min-width: 730px) {
		gap: 0.14rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.17rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.25rem;
	}
}
.heading {
	font-size: 2rem;
	color: #101116;
	font-weight: 600;

	span {
		background: linear-gradient(91deg, #b162ff 64.21%, #5869ff 82.96%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.subHeading {
	font-size: 1.09rem;
	color: #737784;
	font-weight: 500;
	font-family: "Inter";

	@media screen and (min-width: 730px) {
		font-size: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.25rem;
	}
}
.headerImg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	z-index: 1;
	img {
		width: 100%;
		height: 100%;
		// aspect-ratio: 0.74;
		object-fit: contain;

		@media screen and (min-width: 730px) {
			// aspect-ratio: 2.23;
		}
	}

	.video{
		width: 100%;
		border-radius: .8rem;

		@media screen and (min-width: 730px) {
			width: 85%;
		}
	}
}

.driveContentContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	// margin-top: 5rem;
	// margin-bottom: 5rem;

	// @media screen and (min-width: 730px) {
	// 	margin-top: 4rem;
	// 	margin-bottom: 5.71rem;
	// }

	// @media screen and (min-width: 1000px) {
	// 	margin-top: 5rem;
	// 	margin-bottom: 7.02rem;
	// }

	// @media screen and (min-width: 1400px) {
	// 	margin-top: 7rem;
	// 	margin-bottom: 9.87rem;
	// }

	// @media screen and (min-width: 1700px) {
	// 	margin-top: 7.5rem;
	// 	margin-bottom: 10.62rem;
	// }
}


.downloadContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	background-color: #4359f6;
	gap: 2.62rem;
	padding: 2.88rem 1.12rem 5.37rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		padding: 0.45206rem 2.48731rem 0.49444rem 3.294rem;
		gap: 5.74769rem;
		border-radius: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
		padding: 0.57513rem 3.05394rem 0.58706rem 4.04444rem;
		gap: 7.05713rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 1.68rem;
		gap: 9.92rem;
		padding: 0.84388rem 4.29463rem 0.79038rem 5.6875rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 2rem;
		gap: 10.69rem;
		padding: 0.88rem 4.62rem 0.88rem 6.12rem;
	}
}
.downloadLeftContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 2.62rem;

	@media screen and (min-width: 730px) {
		gap: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.7rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 2.21rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2.62rem;
	}
}
.downloadHeader {
	font-size: 2rem;
	font-weight: 600;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.76rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.73rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 3.25rem;
	}
}

.download_Os_container {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 730px) {
		gap: 2.17rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4rem;
	}
}


.downloadBtnContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.downloadBtn {
	border: none;
	background-color: black;
	outline: none;
	color: #ffffff;
	font-weight: 700;
	cursor: pointer;
	font-family: "Plus Jakarta Sans";

	@media screen and (min-width: 730px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 1.69rem;
		font-size: 0.81rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 2.08rem;
		font-size: 0.99rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 2.625rem;
		padding: 1.05rem 1.47rem;
		font-size: 1.26rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 3.125rem;
		padding: 1.25rem 1.75rem;
		font-size: 1.5rem;
	}
}

.osLogoContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 2.5rem;

	@media screen and (min-width: 730px) {
		justify-content: flex-start;
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}

.osLogos {
	height: 2rem;

	@media screen and (min-width: 730px) {
		height: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		height: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		height: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2rem;
	}
}

.downloadImgContainer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 0 1.7rem;

	img {
		width: 100%;
		aspect-ratio: 0.68;

		@media screen and (min-width: 730px) {
			width: 100%;
			aspect-ratio: 0.68;
		}
	}
	@media screen and (min-width: 730px) {
		width: 100%;
		padding: 0;
	}
}

.driveFeatures_Container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 2.03rem;
	padding: 0 1.5rem;
	text-align: center;

	@media screen and (min-width: 730px) {
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.5rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}

.driveFeatures_header {
	font-size: 1.875rem;
	font-weight: 600;
	padding: 0 1.5rem;
	margin-bottom: 1rem;
	text-align: center;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}

}

.driveFeature1Header{
	font-size: 1rem;
	padding: 0 1.5rem;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}

.drive_Features_content {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	padding-bottom: 1.08rem;
	gap: 0.5rem;

	@media screen and (min-width: 730px) {
		gap: 0.27rem 3.35rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		flex-direction: row;
	}

	@media screen and (min-width: 1000px) {
		gap:  0.33rem 4.12rem;
		padding-bottom: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.42rem 5.2rem;
		padding-bottom: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.5rem 6.19rem;
		padding-bottom: 2rem;
	}
}
// .featureContainer {
// 	display: flex;
// 	flex-direction: column;
// 	gap: 0.5rem;

// 	@media screen and (min-width: 730px) {
// 		gap: 0.27rem;
// 	}

// 	@media screen and (min-width: 1000px) {
// 		gap: 0.33rem;
// 	}

// 	@media screen and (min-width: 1400px) {
// 		gap: 0.42rem;
// 	}

// 	@media screen and (min-width: 1700px) {
// 		gap: 0.5rem;
// 	}
// }
.drive_feature {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.75rem;
	border-radius: 1rem;
	background: rgba(67, 89, 246, 0.02);
	padding: 0.54rem 0.5rem;

	@media screen and (min-width: 730px) {
		gap: 0.41rem;
		padding: 0.54rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.5rem;
		padding: 0.67rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.63rem;
		padding: 0.84rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.75rem;
		padding: 1rem;
	}
}
.featureIcons {
	height: 1.42rem;

	@media screen and (min-width: 730px) {
	}

	@media screen and (min-width: 1000px) {
		height: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		height: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		height: 2.625rem;
	}
}
.featureDescription {
	font-size: 0.87rem;
	font-weight: 500;
	color: #101116;
	text-align: left;
	font-family: "Inter";

	@media screen and (min-width: 730px) {
		font-size: 0.72rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.88rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.12rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.25rem;
	}
}

.simplifiedPrivacyContainer {
	display: flex;
	width: 100%;
	padding: 0 2.12rem;

	@media screen and (min-width: 730px) {
		padding: 0;
	}
}
.simplifiedPrivacyChild {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 3.23rem;

	@media screen and (min-width: 730px) {
		gap: 3.23rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 3.96rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 5.57rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 6rem;
	}
}
.driveFeature1Header {
	font-weight: 400;
}
.privacyContainer {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	width: 100%;
	gap: 1.5rem;

	@media screen and (min-width: 730px) {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		align-content: flex-start;
		gap: 0.81rem;
		width: 100%;
	}

	@media screen and (min-width: 1000px) {
		gap: 1rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.39rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1.5rem;
	}
}

.privacyContent:nth-child(1) {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 1.59rem;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--Stroke-light-grey, #eef2f9);
	padding: 2.26763rem 1.54656rem 2.69163rem 1.54656rem;

	img {
		width: 100%;
		aspect-ratio: 1;
	}
	@media screen and (min-width: 730px) {
		padding: 1.60419rem 1.042rem 1.88994rem 1.042rem;
		border-radius: 1.07rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 1.97063rem 1.27938rem 2.32138rem 1.27938rem;
		border-radius: 1.32rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 2.76631rem 1.79913rem 3.2595rem 1.79913rem;
		border-radius: 1.875rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 2.9945rem 1.93756rem 3.52563rem 1.9375rem;
		border-radius: 2rem;
	}
}

.privacyContentChild {
	display: flex;
	flex-direction: column;

	gap: 1.6rem;
	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.32rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.86rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.addCloudContainer {
	display: flex;
	flex-direction: column;
	padding: 0.91rem;
	gap: 0.73rem;
	border-radius: 1.05825rem;
	background: #fff;
	box-shadow: 0px 4.233px 52.911px 0px rgba(109, 141, 173, 0.15);

	@media screen and (min-width: 730px) {
		padding: 0.61rem;
		gap: 0.49rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.75rem;
		gap: 0.6rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 1.06rem;
		gap: 0.85rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 1.14rem;
		gap: 0.92rem;
	}
}
.cloudHeaderContainer {
	display: flex;
	flex-direction: column;
	gap: 0.62rem;
	@media screen and (min-width: 730px) {
		gap: 0.27rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.45rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.5rem;
	}
}
.addCLoudHeader {
	font-size: 0.876rem;
	color: #1c1b1f;
	font-weight: 500;

	@media screen and (min-width: 730px) {
		font-size: 0.59rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.725rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.019rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.09rem;
	}
}
.addCloudSubHeader {
	font-size: 0.584rem;
	font-weight: 400;
	color: #505050;
	font-family: "Inter";

	@media screen and (min-width: 730px) {
		font-size: 0.393rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.484rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.679rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 0.732rem;
	}
}
.driveContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 0.91rem;

	@media screen and (min-width: 730px) {
		gap: 0.61rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.75rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.06rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1.14rem;
	}
}
.border {
	border: 1px solid #e8e8e8;
}
.googleDrive {
	display: flex;
	flex-direction: row;
	background: #eff3fd;
	width: 100%;
	align-items: center;
	padding: 0.58431rem 0.43825rem;
	gap: 0.44rem;
	border-radius: 0.45481rem;

	@media screen and (min-width: 730px) {
		padding: 0.39369rem 0.29525rem;
		gap: 0.3rem;
		border-radius: 0.30644rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.48338rem 0.3625rem;
		gap: 0.3625rem;
		border-radius: 0.37625rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0.67975rem 0.50981rem;
		gap: 0.51rem;
		border-radius: 0.52913rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.55rem;
		padding: 0.732rem 0.549rem;
		border-radius: 0.56981rem;
	}
}
.oneDrive {
	background: #fafafa;
}
.selectedCircle {
	height: 0.584rem;
	width: 0.584rem;

	@media screen and (min-width: 730px) {
		height: 0.393rem;
		width: 0.393rem;
	}

	@media screen and (min-width: 1000px) {
		height: 0.48rem;
		width: 0.48rem;
	}

	@media screen and (min-width: 1400px) {
		height: 0.679rem;
		width: 0.679rem;
	}

	@media screen and (min-width: 1700px) {
		height: 0.73rem;
		width: 0.73rem;
	}
}
.DriveIcons {
	width: 0.876rem;

	@media screen and (min-width: 730px) {
		width: 0.59rem;
	}

	@media screen and (min-width: 1000px) {
		width: 0.72rem;
	}

	@media screen and (min-width: 1400px) {
		width: 1.019rem;
	}

	@media screen and (min-width: 1700px) {
		width: 1.09rem;
	}
}
.driveHeader {
	font-size: 0.584rem;
	font-weight: 500;
	color: #1c1b1f;
	font-family: "Inter";

	@media screen and (min-width: 730px) {
		font-size: 0.393rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.48rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.679rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 0.732rem;
	}
}
.oneDriveHeader {
	color: #444343;
}

.driveLogoContainer {
	display: flex;
	flex-direction: row;
	gap: 0.58rem;
	align-items: center;

	@media screen and (min-width: 730px) {
		gap: 0.39rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.48rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.73rem;
	}
}

.cloudAccountBtnContainer {
	display: flex;
	flex-direction: column;
	gap: 1.36rem;

	@media screen and (min-width: 730px) {
		gap: 0.92rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.13rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.59rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1.71rem;
	}
}
.cloudNameContainer {
	display: flex;
	flex-direction: column;
	gap: 1.14rem;

	@media screen and (min-width: 730px) {
		gap: 0.06rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.13rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.15rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 0.17rem;
	}
}
.enterCloudName {
	font-size: 0.47rem;
	font-weight: 400;
	color: #444343;

	@media screen and (min-width: 730px) {
		font-size: 0.319rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.392rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.55rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 0.59475rem;
	}
}
.cloudInputContainer {
	display: flex;
	padding: 0.62544rem 0.45481rem;
	align-items: center;
	border-radius: 0.58431rem;
	border: 0.549px solid var(--Primary, #4359f6);
	background: #fff;
	// box-shadow: 0px 0.732px 2.196px 0px rgba(0, 0, 0, 0.05);
	font-size: 0.51rem;
	color: #9c9b9b;
	font-family: "Inter";

	span {
		color: #4359f6;
		font-size: 0.87rem;
		font-weight: 400;

		@media screen and (min-width: 730px) {
			font-size: 0.509rem;
		}

		@media screen and (min-width: 1000px) {
			font-size: 0.722rem;
		}

		@media screen and (min-width: 1400px) {
			font-size: 1.019rem;
		}

		@media screen and (min-width: 1700px) {
			font-size: 1.09rem;
		}
	}

	@media screen and (min-width: 730px) {
		font-size: 0.344rem;
		padding: 0.42138rem 0.30644rem;
		border-radius: 0.39369rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.422rem;
		padding: 0.51738rem 0.37625rem;
		border-radius: 0.48338rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 0.594rem;
		padding: 0.72756rem 0.52913rem;
		border-radius: 0.67975rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 0.6405rem;
		padding: 0.7835rem 0.56981rem;
		border-radius: 0.732rem;
	}
}

.cloudBtnContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 2.34rem;

	@media screen and (min-width: 730px) {
		gap: 0.54rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.66rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 0.93rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 1rem;
	}
}
.cancelButton {
	display: flex;
	align-items: center;
	border: none;
	background: none;
	padding: 0.58rem 0.88rem;
	font-size: 0.59rem;
	border-radius: 0.58431rem;
	font-family: "Inter";

	@media screen and (min-width: 730px) {
		padding: 0.39rem 0.59rem;
		font-size: 0.39rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.48rem 0.73rem;
		font-size: 0.48rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0.68rem 1.02rem;
		font-size: 0.679rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0.732rem 1.098rem;
		border-radius: 0.732rem;
		font-size: 0.732rem;
	}
}
.addCloudBtn {
	display: flex;
	align-items: center;
	background: #4359f6;
	font-weight: 400;
	color: #ffffff;
	border: none;
	padding: 0.5rem 0.88rem;
	font-size: 0.59rem;
	border-radius: 0.58431rem;
	font-family: "Inter";

	@media screen and (min-width: 730px) {
		padding: 0.35rem 0.59rem;
		font-size: 0.39rem;
		border-radius: 0.39369rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 0.4rem 0.73rem;
		font-size: 0.48rem;
		border-radius: 0.48338rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 0.5rem 1.02rem;
		font-size: 0.679rem;
		border-radius: 0.67975rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 0.6rem 1.098rem;
		border-radius: 0.732rem;
		font-size: 0.732rem;
	}
}

.cloudText {
	font-size: 0.99rem;
	color: #737784;
	font-weight: 400;
	font-family: "Inter";

	@media screen and (min-width: 730px) {
		font-size: 0.672rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 0.825rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 1.16rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 1.25rem;
	}
}

.privacyContent:nth-child(2) {
	display: flex;
	flex-direction: column;
	height: 100%;
	border-radius: 1.59rem;
	// justify-content: center;
	align-items: center;
	padding-bottom: 4.25rem;
	border: 2px solid var(--Stroke-light-grey, #eef2f9);
	gap: 1.55rem;

	img {
		width: 95%;
		align-self: flex-end;
		aspect-ratio: 1.21;
	}

	@media screen and (min-width: 730px) {
		gap: 2.04rem;
		padding-bottom: 0;
		border-radius: 1.07rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.28rem;
		border-radius: 1.32rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 4.5rem;
		border-radius: 1.857rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.5rem;
		border-radius: 2rem;
	}
}

.privacyContent:nth-child(3) {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 1.59rem;
	justify-content: center;
	align-items: center;
	border: 2px solid var(--Stroke-light-grey, #eef2f9);
	padding: 1.61rem 1.65rem 3.39rem 2.05rem;
	gap: 1.55rem;

	img {
		width: 100%;
		aspect-ratio: 1;
	}

	@media screen and (min-width: 730px) {
		padding: 1.09rem 1.11rem 2.25rem 1.38rem;
		gap: 1.04rem;
		border-radius: 1.07rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 1.33rem 1.36rem 2.81rem 1.69rem;
		gap: 1.28rem;
		border-radius: 1.32rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 1.88rem 2rem 3.94rem 2rem;
		gap: 1.8rem;
		border-radius: 1.857rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 2.02rem 2.3rem 4.24rem 2.3rem;
		gap: 1.94rem;
		border-radius: 2rem;
	}
}

.illustrationContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 2.62rem;
	padding: 0 2.12rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 3.76rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 4.62rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 5.83rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 6.94rem;
	}
}
.illustrationText {
	display: flex;
	flex-direction: row;
	align-items: center;
}
.illustrationHeader {
	font-size: 2rem;
	font-weight: 600;

	@media screen and (min-width: 730px) {
		font-size: 2.16rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 2.66rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 3.36rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 4rem;
	}
}
.encryptedSyncImg {
	display: flex;
	width: 100%;

	img {
		width: 100%;
		aspect-ratio: 1.03;
	}
}
.drivePrivacyContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	// padding: 0 1.19rem;
	gap: 2rem;

	@media screen and (min-width: 730px) {
		gap: 2.02rem;
		padding: 0;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.48rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.48rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.75rem;
	}
}

.driveImgContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.62rem;
	padding: 0 1rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 0.64rem;
		padding: 0;
	}
	@media screen and (min-width: 1000px) {
		gap: 0.79rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 1rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 1.19rem;
	}
}

// .driveShareImg1 {
// 	display: flex;
// 	width: 100%;

// 	img {
// 		width: 100%;
// 		aspect-ratio: 1.18;
// 	}

// 	@media screen and (min-width: 730px) {
// 		aspect-ratio: 1.18;
// 		width: 60%;
// 	}
// }
// .driveShareImg2 {
// 	display: flex;
// 	width: 100%;
// 	padding: 0 3rem;

// 	img {
// 		width: 100%;
// 		aspect-ratio: 0.88;

// 		@media screen and (min-width: 730px) {
// 			aspect-ratio: 0.74;
// 		}
// 	}

// 	@media screen and (min-width: 730px) {
// 		flex-direction: row;
// 		width: 39%;
// 		padding: 0;
// 	}
// }
.driveShareImg:nth-child(1){
	display: flex;
	width: 100%;
	img {
		width: 100%;
		aspect-ratio: 1.18;
	}

	@media screen and (min-width: 730px) {
		aspect-ratio: 1.18;
		width: 60%;
	}
}
.driveShareImg:nth-child(2){
	display: flex;
	width: 100%;
	padding: 0 3rem;

	img {
		width: 100%;
		aspect-ratio: 0.88;

		@media screen and (min-width: 730px) {
			aspect-ratio: 0.74;
		}
	}

	@media screen and (min-width: 730px) {
		flex-direction: row;
		width: 39%;
		padding: 0;
	}
}

.recoveryImgContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.62rem;
	padding: 0 0.87rem;

	@media screen and (min-width: 730px) {
		flex-direction: row;
		gap: 0.64rem;
	}
	@media screen and (min-width: 1000px) {
		gap: 0.79rem;
	}
	@media screen and (min-width: 1400px) {
		gap: 1rem;
	}
	@media screen and (min-width: 1700px) {
		gap: 1.19rem;
	}
}

.recoveryChildContainer {
	display: flex;
	flex-direction: column;
	gap: 1.19rem;
	width: 100%;

	@media screen and (min-width: 730px) {
		width: 60%;
	}
}

.recoveryImg {
	display: flex;
	width: 100%;
	padding: 0 3.5rem;

	img {
		width: 100%;
		height: 100%;
		aspect-ratio: 0.7;
	}

	@media screen and (min-width: 730px) {
		width: 39%;
		padding: 0;
	}
}
.recoveryDriveImg {
	display: flex;
	width: 100%;
	img {
		width: 100%;
		object-fit: contain;
	}

	@media screen and (min-width: 730px) {
		width: 100%;
	}
}

.fullPrivacyImg_container {
	display: flex;
	flex-direction: column;

	img {
		width: 100%;
		object-fit: contain;
	}
}

.setUsApartContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2.0625rem 0.6875rem 1.3125rem 0.625rem;
	gap: 1.69rem;

	@media screen and (min-width: 730px) {
		padding: 0;
		gap: 2.15rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.68rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.71rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 4rem;
	}
}
.driveFeatureSubHeader {
	font-size: 1rem;
	font-weight: 500;
	color: #444343;
	text-align: center;
	font-family: "Inter";

	@media screen and (min-width: 730px) {
		font-size: 1.42rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.74rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.2rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.625rem;
	}
}
.setApartImg {
	display: flex;
	flex-direction: column;

	img {
		width: 100%;
		aspect-ratio: 0.51;

		@media screen and (min-width: 730px) {
			aspect-ratio: 1.32;
		}
	}
}

.footerContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: flex-end;
	background-color: #4359f6;
	padding: 2.13rem 1.08rem 4.87rem 0.88rem;
	gap: 3.83rem;

	@media screen and (min-width: 730px) {
		padding: 3.52669rem 1.645rem 3.52669rem 0;
		gap: 1.71rem;
		flex-direction: row-reverse;
		width: 100%;
		justify-content: space-between;
		border-radius: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 1.33rem;
		padding: 4.33rem 2.02rem 4.33rem 0;
		gap: 2.1rem;
	}

	@media screen and (min-width: 1400px) {
		padding: 6.01rem 2.84rem 6.01rem 0;
		border-radius: 1.68rem;
		gap: 2.96rem;
	}

	@media screen and (min-width: 1700px) {
		padding: 6.57rem 3.06rem 6.57rem 0;
		border-radius: 2rem;
		gap: 3.19rem;
	}
}
.footerLeftContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	// justify-content: center;
	gap: 2.62rem;
	width: 100%;
	z-index: 2;

	@media screen and (min-width: 730px) {
		align-items: flex-start;
		width: 50%;
		gap: 1.07rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.32rem;
	}
	@media screen and (min-width: 1200px) {
	}

	@media screen and (min-width: 1400px) {
		gap: 1.86rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}
.footerText_header {
	font-size: 1.74rem;
	font-weight: 600;
	color: #ffffff;

	@media screen and (min-width: 730px) {
		font-size: 1.47rem;
	}

	@media screen and (min-width: 1000px) {
		font-size: 1.81rem;
	}

	@media screen and (min-width: 1400px) {
		font-size: 2.55rem;
	}

	@media screen and (min-width: 1700px) {
		font-size: 2.75rem;
	}
}

.footerDownload_container {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 730px) {
		gap: 1.81rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 2.23rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 3.13rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 3.38rem;
	}
}

.footerBtn_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.FooterDownloadBtn {
	border: none;
	background-color: black;
	outline: none;
	color: #ffffff;
	font-weight: 700;
	cursor: pointer;
	font-family: "Plus Jakarta Sans";

	@media screen and (min-width: 730px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 1.69rem;
		font-size: 0.805rem;
	}

	@media screen and (min-width: 1000px) {
		padding: 0.67775rem 0.94881rem;
		border-radius: 2.08rem;
		font-size: 0.99rem;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 2.625rem;
		padding: 1.05rem 1.47rem;
		font-size: 1.39rem;
	}

	@media screen and (min-width: 1700px) {
		border-radius: 3.125rem;
		padding: 1.25rem 1.75rem;
		font-size: 1.5rem;
	}
}

.footerImgContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 6.05rem;

	img {
		width: 100%;
		aspect-ratio: 1.79;
		z-index: 0;

		@media screen and (min-width: 730px) {
			width: 100%;
			aspect-ratio: 1.79;
		}
	}
	@media screen and (min-width: 730px) {
		width: 50%;
		gap: 0;
		align-items: flex-end;
		justify-content: flex-end;
	}

}
.footerLogoContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 2.5rem;

	@media screen and (min-width: 730px) {
		gap: 1.08rem;
	}

	@media screen and (min-width: 1000px) {
		gap: 1.33rem;
	}

	@media screen and (min-width: 1400px) {
		gap: 1.68rem;
	}

	@media screen and (min-width: 1700px) {
		gap: 2rem;
	}
}